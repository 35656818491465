import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export interface Props {
    className?: string;
}

export const DoNotTellAnyone: React.FC<Props> = ({ className }) => (
    <div className={classNames(styles.box, className)}>
        <span className={styles.text}>
            Не сообщайте никому данные своей учетной записи. Сотрудник GOGYM
            никогда их не спросит. Проверяйте корректность адреса сайта в
            адресной строке своего браузера: business.gogym-dev.com
        </span>
    </div>
);
