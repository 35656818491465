import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    confirmReport,
    fetchBillIsPaid,
    fetchReport,
} from '@redux/modules/reports/actions';
import { getReport } from '@redux/modules/reports/selectors';
import { ReportStatus } from '@t/report';

import styles from './styles.module.css';
import ReportForm from './Form';

export const ReportDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useDispatch<AsyncDispatch>();
    const report = useSelector(getReport(id || ''));
    const history = useHistory();
    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchReport(id));
    }, [dispatch, id]);

    const onSubmit = useCallback(() => {
        if (!id) {
            return;
        }
        dispatch(confirmReport(id))
            .then(() => {
                cogoToast.success('Отчет подтвержден', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/reports`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при попытке изменения статуса отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const billIsPaid = useCallback(() => {
        dispatch(fetchBillIsPaid(id))
            .then(() => {
                cogoToast.success('Отчет оплачен', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/reports`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при попытке изменения статуса отчета', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    if (!report) {
        return null;
    }

    const isNewReport = report.status === ReportStatus.NewReport;
    const isConfirmedReport = report.status === ReportStatus.ReportConfirmed;
    const bookingsReport = Boolean(report.bookings.length);
    const membershipsReport = Boolean(report.memberships.length);

    const initialValues = {
        reportId: report._id,
        date: moment(report.date).format('MM-YYYY'),
        year: report.year,
        month: report.month,
        totalPrice: report.totalPrice,
        totalAmount: report.totalAmountMemberships
            ? `${(report.totalAmount + report.totalAmountMemberships).toFixed(
                  2
              )} ₽`
            : `${report.totalAmount} ₽`,
        status: report.status,
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/reports"
                    title="К списку отчетов"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <ReportForm
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        report={report}
                        isNewReport={isNewReport}
                        isConfirmedReport={isConfirmedReport}
                        billIsPaid={billIsPaid}
                        bookingsReport={bookingsReport}
                        membershipsReport={membershipsReport}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportDetails;
