import React from 'react';
import { InjectedFormProps, Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import XLSX from 'xlsx-js-style';
import fileSaver from 'file-saver';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { Report } from '@t/report';
import { getDuration } from '@containers/Dashboard/ReportList/ReportListTable';
import {
    DATE_FORMAT,
    GYMS_EXEMPTIONS,
    MULTISPORT,
    REPORT_HEADER_CELLS,
    SURFBROTHERS,
    TIME_FORMAT,
    UFCARENA,
} from '@config';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import { BookingType } from '@t/booking';
import { MembershipType } from '@t/membership';

import styles from './styles.module.css';

export interface ReportFormData {
    reportId: string;
    date: string;
    year: number;
    month: number;
    totalPrice: number;
}

export interface Props {
    report: Report;
    isNewReport: boolean;
    isConfirmedReport: boolean;
    billIsPaid: () => void;
    bookingsReport: boolean;
    membershipsReport: boolean;
}

const ReportForm: React.FC<
    Props & InjectedFormProps<ReportFormData, Props>
> = ({
    handleSubmit,
    report,
    isNewReport,
    isConfirmedReport,
    billIsPaid,
    bookingsReport,
    membershipsReport,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const downloadReportBookings = () => {
        const dataForXLSX = report.bookings.map((booking) => {
            if (booking.gymId === MULTISPORT) {
                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.price,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : '一',
                    'Комиссия эквайринга': booking.membershipId ? '一' : 0,
                    'Сумма комиссии эквайринга': 0,
                    'Комиссия GOGYM':
                        getDuration(booking.timeFinished, booking.timeStarted) *
                        2,
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? 0
                        : getDuration(
                              booking.timeFinished,
                              booking.timeStarted
                          ) * 2,
                    'Комиссия GOGYM после вычета скидок': booking.membershipId
                        ? 0
                        : booking.price - Number(booking.netPrice.toFixed(2)),
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            } else if (booking.gymId === SURFBROTHERS) {
                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : '一',
                    'Комиссия эквайринга': booking.membershipId ? '一' : 0,
                    'Сумма комиссии эквайринга': 0,
                    'Комиссия GOGYM':
                        booking.type === BookingType.GroupWorkout
                            ? '30%'
                            : '15%',
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? 0
                        : booking.type === BookingType.GroupWorkout
                        ? booking.initialPrice * 0.3
                        : booking.initialPrice * 0.15,
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            } else if (
                booking.gymTitle.match(
                    /(World Class [А-Я]|World Class Lady's|World Class LITE|UFC Атриум)/
                )
            ) {
                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : '一',
                    'Комиссия эквайринга': booking.membershipId
                        ? '一'
                        : `${booking.acquiringCommission}%`,
                    'Сумма комиссии эквайринга': booking.membershipId
                        ? 0
                        : Number(
                              (
                                  booking.initialPrice *
                                  (booking.acquiringCommission / 100)
                              ).toFixed(2)
                          ),
                    'Комиссия GOGYM': booking.goGymFee ? booking.goGymFee : 500,
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? '一'
                        : booking.priceOfGogymFee
                        ? Number(booking.priceOfGogymFee)
                        : 500,
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            } else if (booking.gymId === UFCARENA) {
                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : '一',
                    'Комиссия эквайринга': booking.membershipId
                        ? '一'
                        : `${booking.acquiringCommission}%`,
                    'Сумма комиссии эквайринга': booking.membershipId
                        ? 0
                        : Number(
                              (
                                  booking.initialPrice *
                                  (booking.acquiringCommission / 100)
                              ).toFixed(2)
                          ),
                    'Комиссия GOGYM': booking.goGymFee ? booking.goGymFee : 300,
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? 0
                        : booking.priceOfGogymFee
                        ? Number(booking.priceOfGogymFee)
                        : 300,
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            } else if (GYMS_EXEMPTIONS.includes(booking.gymId)) {
                const finalPrice = booking.membershipId
                    ? 'Абонемент'
                    : booking.priceAfterCoupon
                    ? Number(booking.priceAfterCoupon)
                    : booking.price
                    ? Number(booking.price.toFixed(2))
                    : booking.initialPrice
                    ? Number(booking.price.toFixed(2))
                    : '一';

                let sumAcquiringCommission = '';
                if (isNaN(Number(finalPrice))) {
                    sumAcquiringCommission = '0';
                } else if (
                    typeof finalPrice === 'number' &&
                    (booking.acquiringCommission / 100) * finalPrice <= 4
                ) {
                    sumAcquiringCommission = '4';
                } else if (
                    typeof finalPrice === 'number' &&
                    (booking.acquiringCommission / 100) * finalPrice > 4
                ) {
                    sumAcquiringCommission = (
                        (booking.acquiringCommission / 100) *
                        finalPrice
                    ).toFixed(2);
                }

                const gogymCommissionAfterDeductingRebates =
                    +finalPrice -
                    Number(sumAcquiringCommission) -
                    Number(booking.netPrice.toFixed(2));

                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(booking.timeFinished)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': finalPrice,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : booking.subscriptionId
                        ? '一'
                        : '一',
                    'Комиссия эквайринга': booking.acquiringCommission
                        ? `${booking.acquiringCommission}%`
                        : '一',
                    'Сумма комиссии эквайринга': Number(sumAcquiringCommission),
                    'Комиссия GOGYM': booking.goGymFee
                        ? booking.goGymFee
                        : `${booking.goGymFee * 100}%`,
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? 0
                        : booking.subscriptionId
                        ? 0
                        : booking.priceOfGogymFee
                        ? Number(booking.priceOfGogymFee)
                        : Number(
                              (
                                  (booking.initialPrice *
                                      Number(
                                          String(booking.goGymFee).slice(0, -1)
                                      )) /
                                  100
                              ).toFixed(2)
                          ),
                    'Комиссия GOGYM после вычета скидок': booking.membershipId
                        ? 0
                        : gogymCommissionAfterDeductingRebates > 0
                        ? Number(
                              gogymCommissionAfterDeductingRebates.toFixed(2)
                          )
                        : 0,
                    'Скидка за счет средств GOGYM': booking.membershipId
                        ? 0
                        : gogymCommissionAfterDeductingRebates <= 0
                        ? Math.abs(gogymCommissionAfterDeductingRebates)
                        : '一',
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            } else {
                const finalPrice = booking.membershipId
                    ? 'Абонемент'
                    : booking.initialPriceForSubscribe
                    ? booking.initialPriceForSubscribe
                    : booking.priceAfterCoupon
                    ? Number(booking.priceAfterCoupon)
                    : booking.price
                    ? Number(booking.price.toFixed(2))
                    : booking.initialPrice
                    ? Number(booking.price.toFixed(2))
                    : '一';

                let sumAcquiringCommission = '';
                if (isNaN(Number(finalPrice)) || !booking.acquiringCommission) {
                    sumAcquiringCommission = '0';
                } else if (
                    typeof finalPrice === 'number' &&
                    (booking.acquiringCommission / 100) * finalPrice <= 4
                ) {
                    sumAcquiringCommission = '4';
                } else if (
                    typeof finalPrice === 'number' &&
                    (booking.acquiringCommission / 100) * finalPrice > 4
                ) {
                    sumAcquiringCommission = (
                        (booking.acquiringCommission / 100) *
                        finalPrice
                    ).toFixed(2);
                }

                const gogymCommissionAfterDeductingRebates =
                    +finalPrice -
                    Number(sumAcquiringCommission) -
                    Number(booking.netPrice.toFixed(2));

                return {
                    'ID Тренировки': booking._id,
                    'ID Клиента': booking.mongoUserId,
                    'Тренажерный зал': booking.gymTitle,
                    'Дата начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время начала тренировки': moment(booking.timeStarted)
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Дата завершения тренировки': moment(
                        booking.timeFinishedForSubscribe
                            ? booking.timeFinishedForSubscribe
                            : booking.timeFinished
                    )
                        .utcOffset(0)
                        .format(DATE_FORMAT),
                    'Время завершения тренировки': moment(
                        booking.timeFinishedForSubscribe
                            ? booking.timeFinishedForSubscribe
                            : booking.timeFinished
                    )
                        .utcOffset(0)
                        .format(TIME_FORMAT),
                    'Продолжительность (мин.)': `${getDuration(
                        booking.timeFinishedForSubscribe
                            ? booking.timeFinishedForSubscribe
                            : booking.timeFinished,
                        booking.timeStarted
                    )}`,
                    'Стоимость (руб.)': booking.membershipId
                        ? 'Абонемент'
                        : booking.initialPriceForSubscribe
                        ? booking.initialPriceForSubscribe
                        : booking.initialPrice,
                    'Финальная стоимость (руб.)': finalPrice,
                    'ID Абонемента': booking.membershipId
                        ? booking.membershipId
                        : booking.subscriptionId
                        ? '一'
                        : '一',
                    'Комиссия эквайринга': booking.acquiringCommission
                        ? `${booking.acquiringCommission}%`
                        : '一',
                    'Сумма комиссии эквайринга': Number(sumAcquiringCommission),
                    'Комиссия GOGYM': booking.goGymFee
                        ? booking.goGymFee
                        : `${booking.goGymFee * 100}%`,
                    'Сумма комиссии GOGYM': booking.membershipId
                        ? 0
                        : booking.subscriptionId &&
                          booking.initialPriceForSubscribe
                        ? Number(
                              (
                                  (booking.initialPriceForSubscribe *
                                      Number(
                                          String(booking.goGymFee).slice(0, -1)
                                      )) /
                                  100
                              ).toFixed(2)
                          )
                        : booking.priceOfGogymFee
                        ? Number(booking.priceOfGogymFee)
                        : Number(
                              (
                                  (booking.initialPrice *
                                      Number(
                                          String(booking.goGymFee).slice(0, -1)
                                      )) /
                                  100
                              ).toFixed(2)
                          ),
                    'Комиссия GOGYM после вычета скидок': booking.membershipId
                        ? 0
                        : gogymCommissionAfterDeductingRebates > 0
                        ? Number(
                              gogymCommissionAfterDeductingRebates.toFixed(2)
                          )
                        : 0,
                    'Скидка за счет средств GOGYM': booking.membershipId
                        ? 0
                        : gogymCommissionAfterDeductingRebates <= 0
                        ? Math.abs(gogymCommissionAfterDeductingRebates)
                        : '一',
                    СТАТУС: booking.paid ? 'Оплачено' : 'Не оплачено',
                    'Перечисление партнеру': booking.membershipId
                        ? 0
                        : Number(booking.netPrice.toFixed(2)),
                };
            }
        });

        const ws = XLSX.utils.json_to_sheet(dataForXLSX);
        for (const i of REPORT_HEADER_CELLS) {
            if (ws[i]) {
                ws[i].s = { font: { bold: true } };
            }
        }

        const totalAmount = [['ИТОГО', report.totalAmount]];

        XLSX.utils.sheet_add_aoa(ws, totalAmount, {
            origin: `R${report.bookings.length + 2}`,
        });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {
            type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        fileSaver.saveAs(
            data,
            `gogym_report_bookings_${report.month}_${report.year}` + '.xlsx'
        );
    };

    const downloadReportMemberships = () => {
        const dataForXLSX = report.memberships.map((membership) => {
            let sumAcquiringCommission = 0;
            if (membership.acquiringCommission === 0) {
                sumAcquiringCommission = 0;
            } else if (
                (membership.acquiringCommission / 100) * membership.price <=
                4
            ) {
                sumAcquiringCommission = 4;
            } else if (
                (membership.acquiringCommission / 100) * membership.price >
                4
            ) {
                sumAcquiringCommission = +(
                    (membership.acquiringCommission / 100) *
                    membership.price
                ).toFixed(2);
            }

            return {
                'ID абонемента': membership._id.toString(),
                'ID клиента': membership.userId,
                'Тренажерный зал': membership.gymTitle
                    ? membership.gymTitle
                    : '一',
                'Дата покупки': membership.createdAt
                    ? moment(membership.timeStarted).format('DD/MM/YYYY')
                    : '一',
                'Тип абонемента': membership.type
                    ? membership.type === MembershipType.TIME
                        ? 'Время'
                        : 'Посещения'
                    : '一',
                'Продолжительность абонемента':
                    membership.type === MembershipType.TIME
                        ? `${membership.duration} дней`
                        : `${membership.duration} посещений`,
                'Ограничение абонемента': membership.limit
                    ? membership.limit
                    : '一',
                'Стоимость (руб.)': membership.price ? membership.price : '一',
                'Финальная стоимость (руб.)': membership.price
                    ? membership.price
                    : '一',
                'Комиссия эквайринга': membership.acquiringCommission
                    ? `${membership.acquiringCommission}%`
                    : '一',
                'Сумма комиссии эквайринга': sumAcquiringCommission,
                'Сумма перечисления до вычета эквайринга': membership.goGymFee
                    ? membership.goGymFee
                    : '一',
                'Комиссия GOGYM после вычета скидок':
                    membership.price -
                    sumAcquiringCommission -
                    Number(membership.netPrice.toFixed(2)),
                СТАТУС: membership.active ? 'Активный' : 'Не активный',
                'Перечисление партнеру': membership.netPrice
                    ? Number(membership.netPrice.toFixed(2))
                    : '一',
            };
        });

        const ws = XLSX.utils.json_to_sheet(dataForXLSX);
        for (const i of REPORT_HEADER_CELLS) {
            if (ws[i]) {
                ws[i].s = { font: { bold: true } };
            }
        }

        const totalAmount = [['ИТОГО', report.totalAmountMemberships]];

        XLSX.utils.sheet_add_aoa(ws, totalAmount, {
            origin: `N${report.memberships.length + 2}`,
        });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {
            type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        fileSaver.saveAs(
            data,
            `gogym_report_memberships_${report.month}_${report.year}` + '.xlsx'
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="date"
                label="Дата отчета"
                component={TextInput}
            />

            <Field
                disabled
                name="totalAmount"
                label="Сумма к перечислению"
                component={TextInput}
            />

            <Field
                disabled
                name="status"
                label="Статус отчета"
                component={TextInput}
            />

            {isNewReport && (
                <Button type="submit" className={styles.btn}>
                    Подтвердить отчет
                </Button>
            )}

            {isConfirmedReport && isSuperAdmin && (
                <Button
                    onClick={billIsPaid}
                    type="button"
                    className={styles.btn}
                >
                    Подтвердить оплату
                </Button>
            )}

            {bookingsReport && (
                <Button
                    onClick={downloadReportBookings}
                    type="button"
                    className={styles.btn}
                >
                    Скачать отчет по тренировкам
                </Button>
            )}

            {membershipsReport && (
                <Button
                    onClick={downloadReportMemberships}
                    type="button"
                    className={styles.btn}
                >
                    Скачать отчет по абонементам
                </Button>
            )}
        </form>
    );
};

export default reduxForm<ReportFormData, Props>({ form: 'Report' })(ReportForm);
