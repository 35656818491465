import React from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    FieldArray,
    formValueSelector,
    change,
} from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import TextInput from '@components/TextInput';
import Button from '@components/Button';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import TextAreaInput from '@components/TextAreaInput';
import SelectInput from '@components/SelectInput';
import { ApplicationState } from '@redux/types';
import {
    GoGymCommission,
    СollaborationType,
    СollaborationTypeOptions,
    СommissionType,
    СommissionTypeOptions,
} from '@t/legal-entity';
import { MembershipType, MembershipTypeOptions } from '@t/membership';
import FileInput from '@components/FileInput';

import styles from './styles.module.css';

export interface FormData {
    name: string;
    contractNumber: string;
    managerEmail: string;
    address: string;
    actualAddress: string;
    inn: string;
    checkingAccount: string;
    correspondentAccount: string;
    bik: string;
    goGymCommission: GoGymCommission[];
    acquiringCommission: number;
    comment: string;
}

export interface Props {
    fileInput: React.MutableRefObject<null>;
    uploadMultipleFiles: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const validate = (values: FormData) => {
    const errors: {
        name: string;
        contractNumber: string;
        managerEmail: string;
        inn: string;
        checkingAccount: string;
        correspondentAccount: string;
        bik: string;
        acquiringCommission: string;
    } = {
        name: '',
        contractNumber: '',
        managerEmail: '',
        inn: '',
        checkingAccount: '',
        correspondentAccount: '',
        bik: '',
        acquiringCommission: '',
    };

    if (!values.name) {
        errors.name = 'Обязательное поле';
    }

    if (!values.contractNumber) {
        errors.contractNumber = 'Введите номер договора';
    }

    if (!values.managerEmail) {
        errors.managerEmail = 'Введите email';
    }

    if (isNaN(Number(values.inn))) {
        errors.inn = 'Должно быть числом';
    }

    if (isNaN(Number(values.checkingAccount))) {
        errors.checkingAccount = 'Должно быть числом';
    }

    if (values?.checkingAccount?.length !== 20) {
        errors.checkingAccount = 'Номер счета должен состоять из 20 цифр';
    }

    if (isNaN(Number(values.correspondentAccount))) {
        errors.correspondentAccount = 'Должно быть числом';
    }

    if (isNaN(Number(values.bik))) {
        errors.bik = 'Должно быть числом';
    }

    if (isNaN(Number(String(values.acquiringCommission).replace(/,/, '.')))) {
        errors.acquiringCommission = 'Должно быть числом';
    }

    return errors;
};

const AddLegalEntityForm: React.FC<
    Props & InjectedFormProps<FormData, Props>
> = ({ handleSubmit, fileInput, uploadMultipleFiles }) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    const renderCommission = ({ fields }: any) => {
        const dispatch = useDispatch();
        const selector = formValueSelector('addLegalEntity');
        const goGymCommissionSelector = (state: ApplicationState) =>
            selector(state, 'goGymCommission');
        const hasGoGymCommission = useSelector(goGymCommissionSelector);

        if (hasGoGymCommission?.length) {
            for (let i = 0; i < hasGoGymCommission.length; i++) {
                if (
                    (hasGoGymCommission[i].commission &&
                        Number(hasGoGymCommission[i].commission) <= 0) ||
                    isNaN(Number(hasGoGymCommission[i].commission))
                ) {
                    hasGoGymCommission[i].commission = '0';
                    dispatch(
                        change(
                            'addLegalEntity',
                            hasGoGymCommission[i].commission,
                            '0'
                        )
                    );
                }
            }
        }

        return (
            <>
                {fields.map(
                    (
                        goGymCommission: Array<{
                            collaborationType: string;
                            commissionType: string;
                            membershipType: string;
                            membershipDuration: number;
                            commission: number;
                        }>,
                        index: number
                    ) => (
                        <div key={index}>
                            <>
                                <Field
                                    name={`${goGymCommission}.collaborationType`}
                                    label="Тип сотрудничества"
                                    component={SelectInput}
                                    options={СollaborationTypeOptions}
                                    onChange={() => {
                                        dispatch(
                                            change(
                                                'addLegalEntity',
                                                `${goGymCommission}.commissionType`,
                                                null
                                            )
                                        );
                                        dispatch(
                                            change(
                                                'addLegalEntity',
                                                `${goGymCommission}.commission`,
                                                null
                                            )
                                        );
                                    }}
                                    emptyTitle={
                                        'Выберите тип сотрудничества...'
                                    }
                                />

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.PerMinWorkout && (
                                    <Field
                                        name={`${goGymCommission}.commissionType`}
                                        label="Вид комиссии"
                                        component={SelectInput}
                                        options={СommissionTypeOptions}
                                        emptyTitle={'Выберите тип комиссии...'}
                                    />
                                )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.GroupWorkout && (
                                    <Field
                                        name={`${goGymCommission}.commissionType`}
                                        label="Вид комиссии"
                                        component={SelectInput}
                                        options={СommissionTypeOptions}
                                        emptyTitle={'Выберите тип комиссии...'}
                                    />
                                )}

                                {((hasGoGymCommission[index].commissionType &&
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.Сommission) ||
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.Cost) && (
                                    <Field
                                        name={`${goGymCommission}.commission`}
                                        label="Комиссия GOGYM"
                                        placeholder="Комиссия GOGYM"
                                        component={TextInput}
                                    />
                                )}

                                {hasGoGymCommission[index].commissionType &&
                                    hasGoGymCommission[index].commissionType ===
                                        СommissionType.TransfersToPartner && (
                                        <Field
                                            name={`${goGymCommission}.commission`}
                                            label="Перечисление партнеру"
                                            placeholder="Перечисление партнеру"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership && (
                                    <Field
                                        name={`${goGymCommission}.membershipType`}
                                        label="Тип абонемента"
                                        placeholder="Тип абонемента"
                                        component={SelectInput}
                                        options={MembershipTypeOptions}
                                        onChange={() => {
                                            dispatch(
                                                change(
                                                    'addLegalEntity',
                                                    `${goGymCommission}.membershipDuration`,
                                                    null
                                                )
                                            );
                                            dispatch(
                                                change(
                                                    'addLegalEntity',
                                                    `${goGymCommission}.commission`,
                                                    null
                                                )
                                            );
                                        }}
                                    />
                                )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index].membershipType ===
                                        MembershipType.TIME && (
                                        <Field
                                            name={`${goGymCommission}.membershipDuration`}
                                            label="Продолжительность абонемента (дни)"
                                            placeholder="Продолжительность абонемента (дни)"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index].membershipType ===
                                        MembershipType.SESSION && (
                                        <Field
                                            name={`${goGymCommission}.membershipDuration`}
                                            label="Продолжительность абонемента (тренировки)"
                                            placeholder="Продолжительность абонемента (тренировки)"
                                            component={TextInput}
                                        />
                                    )}

                                {hasGoGymCommission[index].collaborationType ===
                                    СollaborationType.Membership &&
                                    hasGoGymCommission[index]
                                        .membershipDuration && (
                                        <Field
                                            name={`${goGymCommission}.commission`}
                                            label="Перечисление партнеру"
                                            placeholder="Перечисление партнеру"
                                            component={TextInput}
                                        />
                                    )}

                                <div className={styles.wrapBtn}>
                                    <button
                                        className={styles.acquiringBtn}
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            </>
                        </div>
                    )
                )}

                <button
                    className={styles.acquiringBtn}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Добавить тип сотрудничества
                </button>
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                name="name"
                label="Название юридического лица"
                placeholder="Название юридического лица"
                component={TextInput}
            />

            <Field
                name="contractNumber"
                label="Номер договора"
                placeholder="Номер договора"
                component={TextInput}
            />

            <Field
                name="managerEmail"
                label="Email менеджера"
                placeholder="Email менеджера"
                component={TextInput}
            />

            <Field
                name="address"
                label="Юридический адрес"
                placeholder="Юридический адрес"
                component={TextAreaInput}
            />

            <Field
                name="actualAddress"
                label="Фактический адрес"
                placeholder="Фактический адрес"
                component={TextAreaInput}
            />

            <Field
                name="inn"
                label="ИНН"
                placeholder="ИНН"
                component={TextInput}
            />

            <Field
                name="checkingAccount"
                label="Расчетный счет"
                placeholder="Расчетный счет"
                component={TextInput}
            />

            <Field
                name="correspondentAccount"
                label="Корреспондентский счёт"
                placeholder="Корреспондентский счёт"
                component={TextInput}
            />

            <Field
                name="bik"
                label="БИК"
                placeholder="БИК"
                component={TextInput}
            />

            <FieldArray name="goGymCommission" component={renderCommission} />

            <Field
                name="acquiringCommission"
                label="Комиссия эквайринга"
                placeholder="Комиссия эквайринга"
                component={TextInput}
            />

            <Field
                name="comment"
                label="Комментарий"
                placeholder="Комментарий для юр лица"
                component={TextAreaInput}
            />

            <FileInput
                className="docs-input"
                name="docs"
                label="Загрузить документы"
                accept=".pdf"
                fileInput={fileInput}
                multiple
                onChange={uploadMultipleFiles}
            />

            {isSuperAdmin && (
                <Button type="submit" className={styles.btn}>
                    Добавить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'addLegalEntity',
    validate,
})(AddLegalEntityForm);
