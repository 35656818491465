import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';

import { fetchReports } from '@redux/modules/reports/actions';
import Box from '@components/Box';
import { getSelectedGymId } from '@redux/modules/gyms/selectors';
import { getReports } from '@redux/modules/reports/selectors';
import Loader from '@components/Loader';
import { AsyncDispatch } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';

import { ReportsListTable } from './ReportListTable';
import styles from './styles.module.css';

export const ReportsList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const reports = useSelector(getReports);
    const selectedGymId = useSelector(getSelectedGymId);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;

    useEffect(() => {
        if (!isSuperAdmin) {
            setIsLoading(true);
            dispatch(fetchReports(selectedGymId))
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке отчетов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setIsLoading(false));
        } else if (isSuperAdmin && selectedGymId) {
            setIsLoading(true);
            dispatch(fetchReports(selectedGymId))
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке отчетов', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setIsLoading(false));
        }
    }, [dispatch, selectedGymId]);

    return (
        <div>
            <h1>Отчеты</h1>

            {isLoading && <Loader />}

            {!isLoading && reports.length > 0 && (
                <Box>
                    <div className={styles.tableWrapper}>
                        <ReportsListTable reports={reports} />
                    </div>
                </Box>
            )}

            {!isLoading && !reports.length && <div>Отчетов не найдено</div>}
        </div>
    );
};

export default ReportsList;
