import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';
import { useDebounce } from 'hooks/debounce';

import { fetchCities } from '@redux/modules/gyms/actions';
import { AsyncDispatch } from '@redux/types';
import { City } from '@t/gym';

import styles from './styles.module.css';

export interface Props {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    setFieldValue: any;
}

const CityInput: React.FC<Props> = (props: any) => {
    const dispatch = useDispatch<AsyncDispatch>();
    const [city, setCity] = useState<string>(props.value.title || '');
    const [defValue, setDefValue] = useState<string>(props.value.title || '');
    const [suggestions, updateSuggestions] = useState<City[]>([]);
    const [dropdown, setDropdown] = useState(false);
    const debounced = useDebounce(city);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (!debounced || debounced.length < 3 || defValue) {
            return;
        }
        if (firstUpdate.current) {
            dispatch(fetchCities(debounced))
                .then((response) => {
                    setDropdown(true);
                    if (response.length) {
                        updateSuggestions(response.slice(0, 10));
                        firstUpdate.current = false;
                    } else {
                        updateSuggestions([]);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке города', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        }
    }, [debounced]);

    const onPickSuggestion = useCallback((suggestion) => {
        setCity(suggestion.city);
        updateSuggestions([]);
        setDropdown(false);
        props.setFieldValue('city', {
            title: suggestion.city,
            id: suggestion._id,
        });
    }, []);

    return (
        <div className={styles.wrapper}>
            <label htmlFor={props.name} className={styles.label}>
                {props.label}
            </label>
            <input
                value={city}
                onChange={(e) => {
                    setCity(e.target.value);
                    firstUpdate.current = true;
                    setDefValue('');
                    props.setFieldValue('city', {
                        title: '',
                        id: '',
                    });
                }}
                name={props.name}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.disabled}
                className={styles.input}
            />
            <div className={styles.dropdown}>
                {dropdown && (
                    <ul className={styles.dropdownList}>
                        {suggestions.length > 0
                            ? (suggestions || []).map((suggestion: City) => {
                                  const onClick = () =>
                                      onPickSuggestion(suggestion);
                                  return (
                                      <li key={suggestion._id}>
                                          <button
                                              type="button"
                                              className={styles.suggestion}
                                              onClick={onClick}
                                          >
                                              <span>
                                                  {suggestion.city},{' '}
                                                  {suggestion.regionName}
                                              </span>
                                          </button>
                                      </li>
                                  );
                              })
                            : debounced.length > 2 && (
                                  <li>
                                      <span className={styles.noSuggestion}>
                                          Ничего не найдено
                                      </span>
                                  </li>
                              )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default CityInput;
