import React from 'react';
import moment from 'moment';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { monthNames, Report } from '@t/report';

import Pagination from './Pagination';
import styles from './styles.module.css';

export interface Props {
    reports: Report[];
}

export const getDuration = (end: string, start: string): number =>
    Math.round(moment.duration(moment(end).diff(moment(start))).asMinutes());

export const isValidDate = (value: Date): boolean =>
    value?.toLocaleString() !== 'Invalid Date';

const columns: Column<Report>[] = [
    {
        Header: 'Год',
        accessor: 'year',
    },
    {
        Header: 'Месяц',
        accessor: (report: Report) => monthNames[report.month - 1],
    },
    {
        Header: 'Итоговая сумма',
        accessor: (report: Report) =>
            report.totalAmountMemberships
                ? `${(
                      report.totalAmount + report.totalAmountMemberships
                  ).toFixed(2)} ₽`
                : `${report.totalAmount} ₽`,
    },
    {
        Header: 'Статус отчета',
        accessor: 'status',
    },
];

export const ReportsListTable: React.FC<Props> = ({ reports }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            // @ts-ignore
            columns,
            data: reports || [],
            initialState: {
                pageIndex: 0,
                pageSize: 100,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const report = row.original;
                            history.push(`/dashboard/reports/${report._id}`);
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
        </>
    );
};

export default ReportsListTable;
