import React, {
    useEffect,
    useMemo,
    useCallback,
    useState,
    useRef,
    SyntheticEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import cogoToast from 'cogo-toast';
import moment from 'moment';

import Box from '@components/Box';
import {
    getFoundGyms,
    getGyms,
    getGymsWithSpecialPrice,
    getHiddenGyms,
} from '@redux/modules/gyms/selectors';
import {
    fetchGyms,
    fetchGymsList,
    fetchGymsWithSpecialPrice,
    fetchHiddenGyms,
    fetchScrollGyms,
    filterGymsWithSpecialPrice,
    removeSpecialPrice,
    searchGyms,
} from '@redux/modules/gyms/actions';
import BoxButton from '@components/BoxButton';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import Toggle from '@components/Toggle';
import { AsyncDispatch } from '@redux/types';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { DATE_TIME_FORMAT } from '@config';

import GymListTable from './GymListTable';
import HiddenGymListTable from './HiddenGymListTable';
import styles from './styles.module.css';

export const GymList: React.FC = () => {
    const dispatch = useDispatch<AsyncDispatch>();
    const gyms = useSelector(getGyms);
    const hiddenGyms = useSelector(getHiddenGyms);
    const foundGyms = useSelector(getFoundGyms);
    const gymsWithSpecialPrice = useSelector(getGymsWithSpecialPrice);
    const history = useHistory();
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const isAdmin = user?.role === UserRole.Admin;
    const gymSearchingField = useRef<any>();
    const [foundGymsToggle, setFoundGymsToggle] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [gymIsOver, setGymIsOver] = useState(false);
    const [filterHiddenGyms, setFilterHiddenGyms] = useState<any>();
    const [filterSpecialPriceGyms, setFilterSpecialPriceGyms] = useState<any>();
    const [loadingGymsWithSpecialPrice, setGymsWithSpecialPrice] = useState(
        false
    );
    const [loadingDeleteSpecialPrice, setLoadingDeleteSpecialPrice] = useState(
        false
    );
    const [modalActive, setModalActive] = useState(false);

    const scrollHandler = (event: any) => {
        if (
            event.target.documentElement.scrollHeight -
                (event.target.documentElement.scrollTop + window.innerHeight) <
                400 &&
            event.target.documentElement.scrollTop > 1000
        ) {
            setFetching(true);
        }
    };

    const handleSearchSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const value = gymSearchingField.current?.value;
        dispatch(searchGyms({ value }))
            .then((gyms) => {
                setFoundGymsToggle(true);
                setGymIsOver(true);
                if (!gyms.length) {
                    cogoToast.error('Фитнес площадка не найдена', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при поиске фитнес-площадки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchGymsList());
        dispatch(fetchGyms(0))
            .then((gyms) => {
                if (gyms.length < 99) {
                    setLoading(false);
                    setGymIsOver(true);
                } else {
                    setLoading(false);
                    setGymIsOver(false);
                }
            })
            .catch(() => {
                cogoToast.error('Ошибка при загрузке фитнес-площадок', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            })
            .finally(() => setFetching(false));
        dispatch(fetchHiddenGyms());
        dispatch(fetchGymsWithSpecialPrice());
    }, [dispatch]);

    useEffect(() => {
        if (fetching && !gymIsOver) {
            setLoading(true);
            dispatch(fetchScrollGyms(gyms.length))
                .then((gyms) => {
                    if (gyms.length < 99) {
                        setLoading(false);
                        setGymIsOver(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при загрузке фитнес-площадок', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setFetching(false));
        }
    }, [dispatch, fetching]);

    const dataForCSV = useMemo(
        () => [
            ['ID', 'Название', 'Адрес', 'Цена', 'Адрес'],
            ...gyms.map((gym) => [
                gym._id,
                gym.title,
                gym.address,
                gym.pricePerMinRub,
                gym.address,
            ]),
        ],
        [gyms]
    );

    const filenameForCSV = `gogym_gyms_${Date.now()}`;

    const onAddGymBtnClick = useCallback(
        () => history.push('/dashboard/add-gym'),
        [history]
    );

    const handleSearchGyms = () => {
        setFilterHiddenGyms(
            hiddenGyms.filter((gym) =>
                gym.title
                    .toString()
                    .toLowerCase()
                    .includes(gymSearchingField.current.value.toLowerCase())
            )
        );
    };

    const handleSpecialPriceGyms = () => {
        setFilterSpecialPriceGyms(
            gymsWithSpecialPrice.filter((gym) =>
                gym.title
                    .toString()
                    .toLowerCase()
                    .includes(gymSearchingField.current.value.toLowerCase())
            )
        );
    };

    const onChangeSearchGyms = () => {
        if (!gymSearchingField.current.value) {
            setGymIsOver(false);
            setFoundGymsToggle(false);
            setFetching(false);
            dispatch(fetchGyms(0));
            dispatch(fetchGymsWithSpecialPrice());
        }
    };

    const onAddSpecialPriceBtnClick = useCallback(
        () => history.push('/dashboard/add-special-price'),
        [history]
    );

    const filterGyms = useCallback(
        () =>
            dispatch(
                filterGymsWithSpecialPrice(foundGyms.length ? foundGyms : gyms)
            ),
        [gyms]
    );

    const onRemoveSpecialPriceBtnClick = useCallback(
        (id) => {
            setLoadingDeleteSpecialPrice(true);
            dispatch(removeSpecialPrice(id))
                .then((gym) => {
                    cogoToast.success(
                        `Специальная цена удалена у ${gym.title}`,
                        {
                            position: 'top-right',
                            hideAfter: 5,
                        }
                    );
                })
                .catch(() => {
                    cogoToast.error('Ошибка при удалении специальной цены', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                })
                .finally(() => setLoadingDeleteSpecialPrice(false));
        },
        [dispatch]
    );

    return (
        <>
            <h1>Фитнес-площадки</h1>

            <h3>Действия</h3>
            <div className={styles.actions}>
                <BoxButton
                    icon="gym"
                    title="Добавить площадку"
                    disabled={isAdmin}
                    onClick={onAddGymBtnClick}
                    className={styles.actionBtn}
                />

                <CSVLink filename={filenameForCSV} data={dataForCSV}>
                    <BoxButton
                        icon="spreadsheet"
                        title="Выгрузить данные"
                        disabled={isAdmin}
                        onClick={() => {
                            // do nothing
                        }}
                        className={styles.actionBtn}
                    />
                </CSVLink>

                {isSuperAdmin && (
                    <BoxButton
                        title="Добавить спец цену"
                        disabled={isAdmin}
                        onClick={onAddSpecialPriceBtnClick}
                        className={styles.specialBtn}
                    />
                )}

                {isSuperAdmin && (
                    <BoxButton
                        title={
                            loadingDeleteSpecialPrice
                                ? 'Удаляем...'
                                : 'Удалить спец цену'
                        }
                        disabled={isAdmin}
                        onClick={() => setModalActive(true)}
                        className={styles.specialBtn}
                    />
                )}
            </div>
            {isSuperAdmin && (
                <div className={styles.wpapper}>
                    <div className={styles.filter}>
                        {loadingGymsWithSpecialPrice && !isToggled && (
                            <input
                                className={styles.input}
                                type="search"
                                name="value"
                                placeholder="Поиск по спец ценам"
                                onChange={handleSpecialPriceGyms}
                                ref={gymSearchingField}
                            />
                        )}
                        {!isToggled && !loadingGymsWithSpecialPrice && (
                            <form
                                className={styles.wpapper}
                                onSubmit={handleSearchSubmit}
                            >
                                <input
                                    className={styles.input}
                                    onChange={onChangeSearchGyms}
                                    type="search"
                                    name="value"
                                    placeholder="Поиск по названию"
                                    ref={gymSearchingField}
                                />
                                <Button
                                    onClick={handleSearchSubmit}
                                    type="button"
                                    className={styles.btn}
                                >
                                    Поиск
                                </Button>
                            </form>
                        )}
                        {isToggled && (
                            <input
                                className={styles.input}
                                type="search"
                                name="value"
                                placeholder="Поиск по скрытым"
                                onChange={handleSearchGyms}
                                ref={gymSearchingField}
                            />
                        )}
                        {isSuperAdmin && loadingGymsWithSpecialPrice
                            ? !isToggled && (
                                  <Button
                                      onClick={() =>
                                          setGymsWithSpecialPrice(false)
                                      }
                                      type="button"
                                      className={styles.btn}
                                  >
                                      Все
                                  </Button>
                              )
                            : !isToggled && (
                                  <Button
                                      onClick={() => {
                                          setGymsWithSpecialPrice(true);
                                          setGymIsOver(true);
                                          foundGymsToggle && filterGyms();
                                      }}
                                      type="button"
                                      className={styles.btn}
                                  >
                                      Со спец ценой
                                  </Button>
                              )}
                    </div>
                    <div className={styles.toggle}>
                        <p className={styles.toggleTitle}>
                            Показать скрытые фитнес-площадки
                        </p>

                        <Toggle
                            isToggled={isToggled}
                            onToggle={() => setIsToggled(!isToggled)}
                        />
                    </div>
                </div>
            )}

            {isToggled &&
                (hiddenGyms.length ? (
                    <Box className={styles.container}>
                        <div className={styles.tableWrapper}>
                            {filterHiddenGyms ? (
                                <HiddenGymListTable
                                    hiddenGyms={filterHiddenGyms}
                                />
                            ) : (
                                <HiddenGymListTable hiddenGyms={hiddenGyms} />
                            )}
                        </div>
                    </Box>
                ) : (
                    <h3 className={styles.noHiddenGyms}>
                        Скрытых фитнес-площадок нет.
                    </h3>
                ))}

            {!isToggled && loadingGymsWithSpecialPrice && (
                <Box className={styles.container}>
                    <div className={styles.tableWrapper}>
                        {filterSpecialPriceGyms ? (
                            <GymListTable
                                gyms={filterSpecialPriceGyms}
                                loading={loading}
                            />
                        ) : (
                            <GymListTable
                                gyms={gymsWithSpecialPrice}
                                loading={loading}
                            />
                        )}
                    </div>
                </Box>
            )}

            {!loadingGymsWithSpecialPrice && !isToggled && (
                <Box className={styles.container}>
                    <div className={styles.tableWrapper}>
                        {foundGymsToggle ? (
                            <GymListTable gyms={foundGyms} loading={loading} />
                        ) : (
                            <GymListTable gyms={gyms} loading={loading} />
                        )}
                    </div>
                </Box>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                {gymsWithSpecialPrice.length ? (
                    <ul className={styles.specialPriceUl}>
                        {gymsWithSpecialPrice.map((gym) => (
                            <li className={styles.specialPriceLi} key={gym._id}>
                                <div className={styles.specialPriceWrap}>
                                    <h3 className={styles.specialPriceGymTitle}>
                                        {gym.title}
                                    </h3>
                                    {gym.specialLowPrice &&
                                    gym.specialLowPrice.actionStart ? (
                                        <p className={styles.specialPriceDate}>
                                            {`${moment(
                                                gym.specialLowPrice.actionStart
                                            )
                                                .utcOffset(0)
                                                .format(
                                                    DATE_TIME_FORMAT
                                                )} 一 ${moment(
                                                gym.specialLowPrice.actionEnd
                                            )
                                                .utcOffset(0)
                                                .format(DATE_TIME_FORMAT)}`}
                                        </p>
                                    ) : (
                                        <p className={styles.specialPriceDate}>
                                            一
                                        </p>
                                    )}

                                    <p className={styles.specialPrice}>
                                        {gym.specialLowPrice &&
                                            `${gym.specialLowPrice.price}₽`}
                                    </p>
                                </div>

                                <button
                                    type="button"
                                    className={styles.specialPriceBtn}
                                    onClick={() =>
                                        onRemoveSpecialPriceBtnClick(gym._id)
                                    }
                                >
                                    &times;
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Нет фитнес-площадок со спец ценой</p>
                )}
            </Modal>
        </>
    );
};

export default GymList;
