import React, { useState, useRef } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    FieldArray,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';

import { SpecialPrice, SubwayRoute, WeekDay, WeekDaysOptions } from '@t/gym';
import SelectInput from '@components/SelectInput';
import TimeInput from '@components/TimeInput';
import CheckBoxInput from '@components/CheckBoxInput';
import GeoInput from '@components/GeoInput';
import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';
import Button from '@components/Button';
import { ApplicationState } from '@redux/types';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import MultiSelectInput from '@components/MultiSelectInput';

import styles from './styles.module.css';

export interface FormData {
    hasPerMinWorkouts: boolean;
    hasGroupWorkouts: boolean;
    hasSingleWorkouts: boolean;
    withSingleWorkoutsOption: boolean;
    withGroupWorkoutsOption: boolean;
    hasMinWorkoutDuration: boolean;
    title: string;
    authorized: boolean;
    deleted: boolean;
    address: string;
    description: string;
    descriptionLong: string;
    geo: {
        address: string;
        position: google.maps.LatLngLiteral;
    };
    social: {
        instagram: string;
        vk: string;
        facebook: string;
    };
    imagesSrc: string[];
    specialPrices: Array<SpecialPrice>;
    subwayRoutes: Array<SubwayRoute>;
    fixedPrice: number;
    tags: {
        dayAndNight?: boolean;
        drinkingWater?: boolean;
        towels?: boolean;
        pilates?: boolean;
        crossfit?: boolean;
        yoga?: boolean;
        stretching?: boolean;
        combatZone?: boolean;
        finnishSauna?: boolean;
        turkishHammam?: boolean;
        fitnessBar?: boolean;
        swimmingPool?: boolean;
        childrenRoom?: boolean;
        parking?: boolean;
        cardioEquipment?: boolean;
        shower?: boolean;
    };
    website: string;
    phone: string;
    hasWeekendPrimeTime: boolean;
    hasWeekDayPrimeTime: boolean;
    legalEntityId: string;
    managersId: string[];
    hasPassport: boolean;
}

export interface Props {
    legalEntitysOptions: {
        title: string;
        value: string;
    }[];
    managersOptions: {
        title: string;
        value: string;
    }[];
}

const Form: React.FC<Props & InjectedFormProps<FormData, Props>> = ({
    handleSubmit,
    legalEntitysOptions,
    managersOptions,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [foundManager, setFoundManager] = useState<any>();
    const managerSearchingField = useRef<any>();

    const handleSearchManagers = () => {
        setFoundManager(
            managersOptions.filter((managerOption) =>
                managerOption.title
                    .toLowerCase()
                    .includes(managerSearchingField.current.value.toLowerCase())
            )
        );
    };

    const selector = formValueSelector('addGym');
    const hasSingleWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'hasSingleWorkouts');
    const hasWeekDayPrimeTimeSelector = (state: ApplicationState) =>
        selector(state, 'hasWeekDayPrimeTime');
    const hasWeekendPrimeTimeSelector = (state: ApplicationState) =>
        selector(state, 'hasWeekendPrimeTime');
    const hasPerMinWorkoutsSelector = (state: ApplicationState) =>
        selector(state, 'hasPerMinWorkouts');
    const hasMinWorkoutDurationSelector = (state: ApplicationState) =>
        selector(state, 'hasMinWorkoutDuration');
    const hasSingleWorkouts = useSelector(hasSingleWorkoutsSelector);
    const hasWeekDayPrimeTime = useSelector(hasWeekDayPrimeTimeSelector);
    const hasWeekendPrimeTime = useSelector(hasWeekendPrimeTimeSelector);
    const hasPerMinWorkouts = useSelector(hasPerMinWorkoutsSelector);
    const hasMinWorkoutDuration = useSelector(hasMinWorkoutDurationSelector);

    const renderWorkingHours = ({ fields }: any) => {
        return (
            <div>
                <Button
                    className={styles.btn}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Добавить время работы
                </Button>
                <ul>
                    {fields.map(
                        (
                            workingHours: Array<{
                                weekDay: WeekDay;
                                timeStart: Date;
                                timeFinish: Date;
                                aroundTheClock: boolean;
                            } | null>,
                            index: number
                        ) => (
                            <li key={index}>
                                <div className={styles.working}>
                                    <Field
                                        name={`${workingHours}.weekDay`}
                                        label="День недели"
                                        placeholder="понедельник"
                                        options={WeekDaysOptions}
                                        defaulVal={-1}
                                        component={SelectInput}
                                    />

                                    <Field
                                        required
                                        convertToString
                                        name={`${workingHours}.timeStart`}
                                        label="Открытие"
                                        placeholder="10:00"
                                        component={TimeInput}
                                    />

                                    <Field
                                        required
                                        convertToString
                                        name={`${workingHours}.timeFinish`}
                                        label="Закрытие"
                                        placeholder="12:00"
                                        component={TimeInput}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name={`${workingHours}.aroundTheClock`}
                                        label="Круглосуточно"
                                        component={CheckBoxInput}
                                    />

                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                    >
                                        Удалить
                                    </Button>
                                </div>
                            </li>
                        )
                    )}
                </ul>
            </div>
        );
    };

    const renderSpecialPrices = ({ fields }: any) => {
        return (
            <div>
                <Button
                    className={styles.btn}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Добавить специальную цену
                </Button>
                <ul>
                    {fields.map(
                        (
                            specialPrice: Array<{
                                weekDay: WeekDay;
                                timeStart: Date;
                                timeFinish: Date;
                                price: number;
                            } | null>,
                            index: number
                        ) => (
                            <li key={index}>
                                <div className={styles.special}>
                                    <Field
                                        name={`${specialPrice}.weekDay`}
                                        label="День недели"
                                        placeholder="понедельник"
                                        options={WeekDaysOptions}
                                        defaulVal={-1}
                                        component={SelectInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        name={`${specialPrice}.timeStart`}
                                        label="Начало"
                                        placeholder="10:00"
                                        component={TimeInput}
                                    />

                                    <Field
                                        required
                                        convertToString
                                        name={`${specialPrice}.timeFinish`}
                                        label="Конец"
                                        placeholder="12:00"
                                        component={TimeInput}
                                    />

                                    <Field
                                        required
                                        name={`${specialPrice}.price`}
                                        label="Цена"
                                        placeholder="5"
                                        component={TextInput}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                    >
                                        Удалить
                                    </Button>
                                </div>
                            </li>
                        )
                    )}
                </ul>
            </div>
        );
    };

    const renderSubwayRoutes = ({ fields }: any) => {
        return (
            <div>
                <Button
                    className={styles.btn}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Добавить метро
                </Button>
                <ul>
                    {fields.map(
                        (
                            subwayRoute: Array<{
                                station: string;
                                duration: number;
                            } | null>,
                            index: number
                        ) => (
                            <li key={index}>
                                <div>
                                    <Field
                                        required
                                        name={`${subwayRoute}.station`}
                                        label="Название метро"
                                        placeholder="Алтуфьево"
                                        component={TextInput}
                                    />

                                    <Field
                                        required
                                        name={`${subwayRoute}.duration`}
                                        label="Время от метро до площадки (мин)"
                                        placeholder="5"
                                        component={TextInput}
                                    />
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                    >
                                        Удалить метро
                                    </Button>
                                </div>
                            </li>
                        )
                    )}
                </ul>
            </div>
        );
    };

    const renderImagesSrc = ({ fields }: { fields: any }) => {
        return (
            <>
                {fields.map((imageSrc: Array<string | null>, index: number) => (
                    <div key={index}>
                        <Field
                            name={`${imageSrc}`}
                            label={`Ссылка на фото ${index + 1}`}
                            placeholder="https://website.ru/images/image.jpg"
                            component={TextInput}
                        />
                    </div>
                ))}
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {isSuperAdmin && (
                <div className={styles.row}>
                    <Field
                        name="authorized"
                        label="Авторизован"
                        component={CheckBoxInput}
                    />
                    <Field
                        name="deleted"
                        label="Скрыт с карты"
                        component={CheckBoxInput}
                    />
                    <Field
                        name="withGroupWorkoutsOption"
                        label="Разрешить групповые тренировки"
                        component={CheckBoxInput}
                    />
                    <Field
                        name="withSingleWorkoutsOption"
                        label="Разрешить разовые тренировки"
                        component={CheckBoxInput}
                    />
                </div>
            )}

            <div className={styles.addForm}>
                <div className={styles.wrap}>
                    <Field
                        required
                        name="title"
                        label="Название"
                        placeholder="Самая лучшая фитнес-площадка"
                        component={TextInput}
                    />

                    <Field
                        name="hasPassport"
                        label="Нужен паспорт"
                        component={CheckBoxInput}
                    />

                    <Field
                        name="hasPerMinWorkouts"
                        label="Есть опция поминутных тренировок"
                        component={CheckBoxInput}
                    />
                    <Field
                        name="hasGroupWorkouts"
                        label="Есть опция групповых тренировок"
                        component={CheckBoxInput}
                    />

                    {isSuperAdmin && (
                        <Field
                            name="hasSingleWorkouts"
                            label="Есть опция разовых тренировок"
                            component={CheckBoxInput}
                        />
                    )}

                    {isSuperAdmin && (
                        <FieldArray
                            name="workingHours"
                            component={renderWorkingHours}
                        />
                    )}

                    {hasPerMinWorkouts && (
                        <div>
                            <Field
                                required
                                min="0"
                                max="100"
                                name="fixedPrice"
                                placeholder="10"
                                label="Цена (поминутная)"
                                component={TextInput}
                            />

                            <FieldArray
                                name="specialPrices"
                                component={renderSpecialPrices}
                            />
                            <Field
                                name="hasMinWorkoutDuration"
                                label="Есть минимальное время поминутной тренировки"
                                component={CheckBoxInput}
                            />
                            {hasMinWorkoutDuration && (
                                <div>
                                    <Field
                                        required
                                        min="0"
                                        max="30"
                                        name="minWorkoutDuration"
                                        placeholder="30"
                                        label="Минимальное время тренироки (в минутах)"
                                        component={TextInput}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {hasSingleWorkouts && (
                        <div>
                            <div>
                                <h3>Будние дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekDayFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        name="singleWorkoutPriceInfo.weekDayPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>
                                <Field
                                    name="hasWeekDayPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekDayPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekDayOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            name="singleWorkoutPriceInfo.weekDayOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>Выходные дни:</h3>
                                <h4>Обычная цена:</h4>
                                <div className={styles.inputListWrapper}>
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekendStartTime"
                                        label="Начало"
                                        placeholder="10:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        convertToString
                                        name="singleWorkoutPriceInfo.weekendFinishTime"
                                        label="Конец"
                                        placeholder="11:00"
                                        className={styles.inputWrapper}
                                        component={TimeInput}
                                    />
                                    <Field
                                        required
                                        name="singleWorkoutPriceInfo.weekendPrice"
                                        placeholder="2000"
                                        label="Цена"
                                        className={styles.inputWrapper}
                                        component={TextInput}
                                    />
                                </div>

                                <Field
                                    name="hasWeekendPrimeTime"
                                    label="Во время низкой нагрузки цена отличается?"
                                    component={CheckBoxInput}
                                />
                                {hasWeekendPrimeTime && (
                                    <div className={styles.inputListWrapper}>
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekendOffPeakStartTime"
                                            label="Начало"
                                            placeholder="10:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            convertToString
                                            name="singleWorkoutPriceInfo.weekendOffPeakFinishTime"
                                            label="Конец"
                                            placeholder="11:00"
                                            className={styles.inputWrapper}
                                            component={TimeInput}
                                        />
                                        <Field
                                            required
                                            name="singleWorkoutPriceInfo.weekendOffPeakPrice"
                                            placeholder="1500"
                                            label="Цена"
                                            className={styles.inputWrapper}
                                            component={TextInput}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <Field
                        required
                        name="description"
                        label="Описание"
                        placeholder="Лучшее, что было с вашим телом"
                        component={TextAreaInput}
                    />

                    {/* <Field
                name="descriptionLong"
                label="Услуги"
                placeholder="Лучшее, что было с вашим телом. Действительно, это лучшее, что было с вашим телом. Наверное, это лучшее, что случилось в вашей жизни. Самый лучший зал открывает для вас свои двери. Тренируйтесь как в последний раз. До конца, до упора. С нами вы забудете, что такое спокойная тренировка. Самый лучший зал не только открывает двери, но и размывает границы болевого порога для всех желающих."
                component={TextAreaInput}
            /> */}

                    <FieldArray
                        name="subwayRoutes"
                        component={renderSubwayRoutes}
                    />
                </div>
                <div className={styles.wrap}>
                    <div className={styles.tags}>
                        <Field
                            name="tags.crossfit"
                            label="Кроссфит"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.yoga"
                            label="Йога"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.pilates"
                            label="Пилатес"
                            component={CheckBoxInput}
                        />
                    </div>
                    <div className={styles.tags}>
                        <Field
                            name="tags.combatZone"
                            label="Зона единоборств/Бокс"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.stretching"
                            label="Стретчинг"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.finnishSauna"
                            label="Финская сауна"
                            component={CheckBoxInput}
                        />
                    </div>

                    <div className={styles.tags}>
                        <Field
                            name="tags.turkishHammam"
                            label="Турецкий хаммам"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.fitnessBar"
                            label="Фитнес-бар"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.swimmingPool"
                            label="Бассейн"
                            component={CheckBoxInput}
                        />
                    </div>
                    <div className={styles.tags}>
                        <Field
                            name="tags.parking"
                            label="Парковка"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.childrenRoom"
                            label="Детская комната"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.towels"
                            label="Полотенца"
                            component={CheckBoxInput}
                        />
                    </div>
                    <div className={styles.tags}>
                        <Field
                            name="tags.drinkingWater"
                            label="Питьевая вода"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.dayAndNight"
                            label="Круглосуточно"
                            component={CheckBoxInput}
                        />
                        <Field
                            name="tags.shower"
                            label="Душ"
                            component={CheckBoxInput}
                        />
                    </div>
                    <div className={styles.tags}>
                        <Field
                            name="tags.cardioEquipment"
                            label="Кардиотренажеры"
                            component={CheckBoxInput}
                        />
                    </div>

                    <Field
                        required
                        name="geo"
                        label="Адрес"
                        placeholder="Адрес"
                        component={GeoInput}
                    />

                    <Field
                        disabled
                        name="social.instagram"
                        label="Инстаграм (только ID)"
                        placeholder="Отключен"
                        component={TextInput}
                    />

                    <Field
                        disabled
                        name="social.facebook"
                        label="Facebook (только ID)"
                        placeholder="Отключен"
                        component={TextInput}
                    />

                    <Field
                        name="social.vk"
                        label="VK (только ID)"
                        placeholder="gogym.fit"
                        component={TextInput}
                    />

                    <Field
                        name="website"
                        label="Ссылка на сайт"
                        placeholder="https://website.ru"
                        component={TextInput}
                    />

                    <Field
                        name="phone"
                        label="Телефон площадки"
                        placeholder="+7 999 999 99 99"
                        component={TextInput}
                    />

                    <FieldArray
                        name="imagesSrc"
                        label="Ссылки на фото"
                        component={renderImagesSrc}
                    />

                    {isSuperAdmin && (
                        <Field
                            name="legalEntityId"
                            label="Юридическое лицо"
                            component={SelectInput}
                            options={legalEntitysOptions}
                            emptyTitle={'Выберите юридическое лицо...'}
                            defaultValue={''}
                        />
                    )}

                    {isSuperAdmin && (
                        <>
                            {foundManager ? (
                                <Field
                                    name="managersId"
                                    label="Все менеджеры"
                                    component={MultiSelectInput}
                                    options={foundManager}
                                    defaultValue={''}
                                />
                            ) : (
                                <Field
                                    name="managersId"
                                    label="Все менеджеры"
                                    component={MultiSelectInput}
                                    options={managersOptions}
                                    defaultValue={''}
                                />
                            )}

                            <input
                                className={styles.input}
                                type="search"
                                placeholder="Поиск по имени и email"
                                ref={managerSearchingField}
                                onChange={handleSearchManagers}
                            />
                        </>
                    )}
                </div>
            </div>

            <Button type="submit" className={styles.btn}>
                Добавить
            </Button>
        </form>
    );
};

export default reduxForm<FormData, Props>({
    form: 'addGym',
})(Form);
