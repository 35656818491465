import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { DATE_TIME_FORMAT } from '@config';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import { getLegalentity } from '@redux/modules/legal-entity/selectors';
import {
    deleteDocs,
    deleteLegalEntity,
    deleteLegalEntityGym,
    fetchLegalEntity,
    updateLegalEntity,
    uploadDocs,
} from '@redux/modules/legal-entity/actions';
import { getGymsList } from '@redux/modules/gyms/selectors';

import { getKeyFromUrl } from '../GymDetails';
import LegalEntityForm, { LegalEntityFormData } from './Form';
import styles from './styles.module.css';

const MAX_LENGTH = 3;
const MAX_SIZE = 20000000;

export const LegalEntityDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const gyms = useSelector(getGymsList);
    const legalEntity = useSelector(getLegalentity(id || ''));
    const history = useHistory();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isLoading, updateIsLoading] = useState<boolean>(false);
    const fileInput = useRef<null>(null);
    const legalEntityDocsUrls =
        legalEntity?.docs?.filter((doc) => doc !== '') || [];

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchLegalEntity(id));
    }, [dispatch, id]);

    const onSubmit = useCallback(
        (values: LegalEntityFormData) => {
            if (!id) {
                return;
            }
            dispatch(updateLegalEntity({ id, values }))
                .then((legalEntity) => {
                    if (legalEntity) {
                        cogoToast.success('Юридическое лицо обновлено', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/legalentity`);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении юридического лица', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [id, dispatch]
    );

    const onDeleteLegalEntity = useCallback(() => {
        dispatch(deleteLegalEntity(id))
            .then(() => {
                cogoToast.success('Юридическое лицо удалено', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/legalentity`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении юридического лица', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const onDeleteLegalEntityGym = useCallback(
        (gymId) => {
            dispatch(deleteLegalEntityGym({ gymId, id }))
                .then((legalEntity) => {
                    if (legalEntity) {
                        cogoToast.success('Фитнес площадка удалена', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при удаление фитнес-площадки', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [dispatch, id]
    );

    const onUploadDocs = useCallback(async () => {
        const files = (fileInput.current || { files: new FileList() }).files;
        if (!files.length) {
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            return;
        }
        updateIsLoading(true);
        await dispatch(uploadDocs({ files, legalEntityId: id }));
        updateIsLoading(false);
        setDisabled(true);
        const form: any = Array.from(
            document.getElementsByClassName('docs-input')
        );
        form[0][0].value = null;
    }, [dispatch]);

    const uploadMultipleFiles = (event: any) => {
        const numOfFiles = Array.from(event.target.files).length;
        if (!numOfFiles) {
            event.preventDefault();
            cogoToast.error(`Приложите файлы для загрузки`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        if (numOfFiles + legalEntityDocsUrls.length > MAX_LENGTH) {
            event.preventDefault();
            cogoToast.error(`Нельзя загрузить более ${MAX_LENGTH} файлов`, {
                position: 'top-right',
                hideAfter: 4,
            });
            event.target.value = null;
            return;
        }
        for (let i = 0; i < numOfFiles; ++i) {
            const validExtensions = ['pdf'];
            const fileExtension = event.target.files[i].type.split('/')[1];
            const fileSize = event.target.files[i].size;

            if (!validExtensions.includes(fileExtension)) {
                event.preventDefault();
                cogoToast.error(`Можно загрузить файлы с расширением pdf`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
                event.target.value = null;
                return;
            }

            if (fileSize > MAX_SIZE) {
                event.preventDefault();
                cogoToast.error(
                    `Нельзя загрузить файл размера более 20MB, файл "${event.target.files[i].name}" слишком большой`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                event.target.value = null;
                return;
            }
        }
    };

    const onDeleteDocsClick = useCallback(
        async (event) => {
            try {
                if (!id) {
                    return;
                }
                const s3Key: string = getKeyFromUrl(event.target.id);
                await dispatch(deleteDocs({ legalEntityId: id, key: s3Key }));
            } catch (err) {
                cogoToast.error(`Ошибка при удалении документа: ${err}`, {
                    position: 'top-right',
                    hideAfter: 4,
                });
            }
        },
        [id, dispatch]
    );

    const goToGym = useCallback(
        (gymId) => history.push(`/dashboard/gyms/${gymId}`),
        [history]
    );

    if (!legalEntity) {
        return null;
    }

    const initialValues = {
        legalEntityId: legalEntity._id,
        name: legalEntity.name,
        gyms: legalEntity.gyms,
        contractNumber: legalEntity.contractNumber,
        managerEmail: legalEntity.managerEmail,
        address: legalEntity.address,
        actualAddress: legalEntity.actualAddress,
        inn: legalEntity.inn,
        checkingAccount: legalEntity.checkingAccount,
        correspondentAccount: legalEntity.correspondentAccount,
        bik: legalEntity.bik,
        goGymFee: legalEntity.goGymFee,
        goGymCommission: legalEntity.goGymCommission,
        acquiringCommission: legalEntity.acquiringCommission,
        createdAt: moment(legalEntity.createdAt).format(DATE_TIME_FORMAT),
        comment: legalEntity.comment,
    };

    const gymsOptions = gyms.map((gym) => ({
        label: gym.title,
        value: gym._id,
    }));

    const gymsValue = gymsOptions.filter((gymsOption) =>
        legalEntity.gyms.includes(gymsOption.value)
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/legalentity"
                    title="К списку юр лиц"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <LegalEntityForm
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        onDeleteLegalEntity={onDeleteLegalEntity}
                        onDeleteLegalEntityGym={onDeleteLegalEntityGym}
                        goToGym={goToGym}
                        gymsOptions={gymsOptions}
                        gymsValue={gymsValue}
                        fileInput={fileInput}
                        onUploadDocs={onUploadDocs}
                        onDeleteDocsClick={onDeleteDocsClick}
                        legalEntityDocsUrls={legalEntityDocsUrls}
                        uploadMultipleFiles={uploadMultipleFiles}
                        isLoading={isLoading}
                        disabled={disabled}
                        setDisabled={setDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default LegalEntityDetails;
