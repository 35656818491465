import React from 'react';
import { useTable, usePagination, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GymMembership, MembershipType } from '@t/membership';

import styles from './styles.module.css';

export interface Props {
    gymMemberships: GymMembership[];
}

export const getDuration = (type: string, duration: number): string => {
    if (type === MembershipType.TIME) {
        return `${duration} дней`;
    } else {
        return `${duration} посещений`;
    }
};

const columns: Column<GymMembership>[] = [
    {
        Header: 'Фитнес площадка',
        accessor: (membership: GymMembership) => {
            return {
                title: membership.gyms.map(
                    (gymTitle) => gymTitle && `${gymTitle}; `
                ),
                styleClass: styles.line,
            };
        },
        Cell: ({ cell: { value } }: any) => (
            <div className={value.styleClass}>{value.title}</div>
        ),
    },
    {
        Header: 'Тип',
        accessor: (membership: GymMembership) => {
            return membership.type === 'TIME' ? 'Время' : 'Посещения';
        },
        Cell: ({ cell: { value } }: any) => <div>{value}</div>,
    },
    {
        Header: 'Срок действия',
        accessor: (membership: GymMembership) =>
            `${getDuration(membership.type, membership.duration)}`,
    },
    {
        Header: 'Цена',
        accessor: (membership: GymMembership) => {
            return membership.price;
        },
    },
    {
        Header: 'Цена в клубе',
        accessor: (membership: GymMembership) => {
            return membership.gymPrice;
        },
    },
];

export const MembershipGymListTable: React.FC<Props> = ({ gymMemberships }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data: gymMemberships || [],
            initialState: {
                pageIndex: 0,
                pageSize: 1000,
            },
        },
        useSortBy,
        usePagination
    );
    const history = useHistory();

    return (
        <>
            <table {...getTableProps()} className={styles.table}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={Math.random()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    key={Math.random()}
                                >
                                    {column.render('Header')}
                                    <span className={styles.sorted}>
                                        <FontAwesomeIcon
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'sort-up'
                                                        : 'sort-down'
                                                    : 'sort'
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const onClick = () => {
                            const gymMembership = row.original;
                            history.push(
                                `/dashboard/gym-memberships/${gymMembership._id}`
                            );
                        };
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={styles.tableRow}
                                onClick={onClick}
                                key={Math.random()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={Math.random()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default MembershipGymListTable;
