import React, { useState } from 'react';
import {
    InjectedFormProps,
    Field,
    reduxForm,
    formValueSelector,
} from 'redux-form';
import { useSelector } from 'react-redux';
import Select, { createFilter, MultiValue } from 'react-select';

import TextInput from '@components/TextInput';
import TextAreaInput from '@components/TextAreaInput';
import { getUser } from '@redux/modules/auth/selectors';
import { UserRole } from '@t/user';
import Button from '@components/Button';
import { GymMembershipTypeOptions, MembershipType } from '@t/membership';
import SelectInput from '@components/SelectInput';
import Modal from '@components/Modal';
import { ApplicationState } from '@redux/types';
import FileInput from '@components/FileInput';
import DeleteButton from '@components/DeleteButton';
import CheckBoxInput from '@components/CheckBoxInput';

import styles from './styles.module.css';

export interface GymMembershipFormData {
    active: boolean;
    membershipId: string;
    type: string;
    duration: number;
    limit: number;
    price: number;
    gymPrice: number | null;
    comment: string;
}

export interface Props {
    gymsOptions: { label: string; value: string }[];
    onDeleteGymMembership: () => void;
    gymsValue: { label: string; value: string }[];
    setSelectedGyms: React.Dispatch<React.SetStateAction<string[]>>;
    fileInput: React.MutableRefObject<null>;
    onUploadDocs: () => Promise<void>;
    gymsMembershipOfferUrls: string[];
    onDeleteDocsClick: (
        event: React.MouseEvent<HTMLInputElement>
    ) => Promise<void>;
    uploadMultipleFiles: (event: React.MouseEvent<HTMLInputElement>) => void;
    isLoading: boolean;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const validate = (values: GymMembershipFormData) => {
    const errors: {
        type: string;
        price: string;
        gymPrice: string;
        duration: string;
        limit: string;
    } = {
        type: '',
        price: '',
        gymPrice: '',
        duration: '',
        limit: '',
    };

    if (!values.type) {
        errors.type = 'Обязательное поле';
    }

    if (
        values.price &&
        (!Number.isInteger(Number(values.price)) ||
            Number(values.price) <= 0 ||
            String(values.price).replace(/^\d+$/g, '').length)
    ) {
        errors.price = 'Должно быть целым положительным числом';
    }

    if (
        values.gymPrice &&
        (!Number.isInteger(Number(values.gymPrice)) ||
            Number(values.gymPrice) <= 0 ||
            String(values.gymPrice).replace(/^\d+$/g, '').length)
    ) {
        errors.gymPrice = 'Должно быть целым положительным числом';
    }

    if (
        values.duration &&
        (!Number.isInteger(Number(values.duration)) ||
            Number(values.duration) <= 0 ||
            String(values.duration).replace(/^\d+$/g, '').length)
    ) {
        errors.duration = 'Должно быть целым положительным числом';
    }

    if (
        values.limit &&
        (!Number.isInteger(Number(values.limit)) ||
            Number(values.limit) <= 0 ||
            String(values.limit).replace(/^\d+$/g, '').length)
    ) {
        errors.limit = 'Должно быть целым положительным числом';
    }

    return errors;
};

const GymMembershipForm: React.FC<
    Props & InjectedFormProps<GymMembershipFormData, Props>
> = ({
    handleSubmit,
    gymsOptions,
    onDeleteGymMembership,
    gymsValue,
    setSelectedGyms,
    fileInput,
    onUploadDocs,
    gymsMembershipOfferUrls,
    onDeleteDocsClick,
    uploadMultipleFiles,
    isLoading,
    disabled,
    setDisabled,
}) => {
    const user = useSelector(getUser);
    const isSuperAdmin = user?.role === UserRole.SuperAdmin;
    const [modalActive, setModalActive] = useState(false);

    const selector = formValueSelector('GymMembershipInfo');
    const typeSelector = (state: ApplicationState) => selector(state, 'type');
    const gymMembershipType = useSelector(typeSelector);

    const handleChange = (
        value: MultiValue<{ label: string; value: string }>
    ) => {
        setSelectedGyms(
            value.map((gym: { label: string; value: string }) => gym.value)
        );
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                disabled
                name="membershipId"
                label="ID абонемента"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="active"
                label="Активность абонемента"
                component={CheckBoxInput}
            />

            <span className={styles.label}>фитнес-площадки</span>
            <Select
                className={styles.select}
                isDisabled={disabled}
                isMulti
                styles={{
                    input: (provided) => ({
                        ...provided,
                        minHeight: 40,
                    }),
                }}
                defaultValue={gymsValue}
                onChange={handleChange}
                options={gymsOptions}
                filterOption={createFilter({
                    stringify: (option) => `${option.label}`,
                })}
                closeMenuOnSelect={false}
                placeholder="Выберите фитнес-площадку"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'rgb(225, 129, 65)',
                    },
                })}
                noOptionsMessage={() => 'Не найдено'}
            />

            <Field
                disabled={disabled}
                name="type"
                label="Тип"
                component={SelectInput}
                options={GymMembershipTypeOptions}
            />

            {gymMembershipType === MembershipType.SESSION ? (
                <Field
                    required
                    disabled={disabled}
                    name="duration"
                    label="Продолжительность абонемента (тренировки)"
                    component={TextInput}
                />
            ) : (
                <Field
                    required
                    disabled={disabled}
                    name="duration"
                    label="Продолжительность абонемента (дни)"
                    component={TextInput}
                />
            )}

            {gymMembershipType === MembershipType.SESSION && (
                <Field
                    disabled={disabled}
                    name="limit"
                    label="Ограничение абонемента по времени (дни)"
                    component={TextInput}
                />
            )}

            <Field
                disabled={disabled}
                name="price"
                label="Цена"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="gymPrice"
                label="Цена в клубе"
                component={TextInput}
            />

            <Field
                disabled={disabled}
                name="comment"
                label="Коментарий"
                placeholder="Здесь можно перечислить услуги абонемента"
                component={TextAreaInput}
            />

            {gymsMembershipOfferUrls.length > 0 && (
                <span className={styles.label}>Документы</span>
            )}
            <div className={styles.images}>
                {gymsMembershipOfferUrls.map((url: string, index: number) => (
                    <div className={styles.row} key={url}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <img
                                alt="pdf"
                                id={`img${index}`}
                                src={
                                    'https://api.gogym.fit/s3-gogym-icons/pdf.svg'
                                }
                                className={styles.gymImage}
                            />
                        </a>
                        <DeleteButton
                            disabled={disabled}
                            id={url}
                            onClick={onDeleteDocsClick}
                        />
                    </div>
                ))}
            </div>

            {gymsMembershipOfferUrls.length < 3 && (
                <>
                    <FileInput
                        disabled={disabled}
                        className="docs-input"
                        name="docs"
                        label="Загрузить документы"
                        accept=".pdf"
                        fileInput={fileInput}
                        multiple
                        onChange={uploadMultipleFiles}
                    />
                    <Button
                        disabled={isLoading ? isLoading : disabled}
                        onClick={onUploadDocs}
                        type="button"
                        className={styles.btn}
                    >
                        {isLoading ? 'Загрузка...' : 'Загрузить документы'}
                    </Button>
                </>
            )}

            {isSuperAdmin && !disabled && (
                <Button type="submit" className={styles.btn}>
                    Обновить
                </Button>
            )}

            {isSuperAdmin && disabled && (
                <Button
                    type="button"
                    onClick={() => setDisabled(false)}
                    className={styles.btn}
                >
                    Изменить
                </Button>
            )}

            <Modal active={modalActive} setActive={setModalActive}>
                <h3>Вы действительно хотите удалить абонемент?</h3>
                {isSuperAdmin && (
                    <Button
                        onClick={onDeleteGymMembership}
                        type="button"
                        className={styles.btnModal}
                    >
                        Удалить
                    </Button>
                )}
            </Modal>

            {isSuperAdmin && (
                <Button
                    onClick={() => setModalActive(true)}
                    type="button"
                    className={styles.btn}
                >
                    Удалить
                </Button>
            )}
        </form>
    );
};

export default reduxForm<GymMembershipFormData, Props>({
    form: 'GymMembershipInfo',
    validate,
})(GymMembershipForm);
