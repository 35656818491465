import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import BackButton from '@components/BackButton';
import { fetchHiddenGym, restoreGym } from '@redux/modules/gyms/actions';
import { getHiddenGym } from '@redux/modules/gyms/selectors';
import { AsyncDispatch } from '@redux/types';
import Button from '@components/Button';

import styles from './styles.module.css';

export const HiddenGym: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const gym = useSelector(getHiddenGym(id || ''));

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchHiddenGym(id));
    }, [dispatch, id]);

    const onRestore = useCallback(() => {
        dispatch(restoreGym(id))
            .then(() => {
                cogoToast.success('Фитнес площадка восстановлена', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/gyms`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при восстановлении фитнес-площадки', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    if (!gym || !gym?._id) {
        return <div className={styles.container}>Загрузка...</div>;
    }

    return (
        <div className={styles.container}>
            <BackButton
                to="/dashboard/gyms"
                title="К списку фитнес-площадок"
                className={styles.backBtn}
            />

            {gym.isHidden && (
                <>
                    <div className={styles.hiddenGymBadge}>
                        Фитнес площадка скрыта командой GOGYM. В настоящее время
                        фитнес-площадка не отображается у пользователей
                        приложения. Пожалуйста, свежитесь с менеджером если
                        произошла ошибка.
                    </div>
                    <Button onClick={onRestore}>Восстановить</Button>
                </>
            )}
        </div>
    );
};

export default HiddenGym;
